import MusicComponent from '../../components/Music/music'
// import Quote from '../../components/Quote/quote';
import './musicPage.scss';



function Music(){

    return (
        <>
            {/* <Quote 
                quote="I want to be the very best, like no one ever was.."
                author="Jason Paige"
            /> */}
            <div className="musicPage-container">
                <h1 className='musicPage-header'>Music</h1>
                <MusicComponent />
            </div>
        </>
    );  
}

export default Music;