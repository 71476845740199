import React from "react";
import Swal from 'sweetalert2'
import './contact.scss';

function ContactForm() {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "c44b06d6-74d7-465d-a893-c78475948574");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
        Swal.fire({
            title: "Awesome! Your message was sent, and I'll respond as soon as possible.",
            width: 600,
            padding: "3em",
            color: "#716add",
            backdrop: `
              rgba(43, 53, 66, 0.8)
              url("/images/nyan-cat.gif")
              left top
              no-repeat
            `
          });
        document.getElementById('makkForm').reset();
        return false;
    }
  };

  return (
      <form onSubmit={onSubmit} id="makkForm" className="contact-container">
        <div className="contact-div">
            <label className="contact-label">Full Name</label>
            <input className="contact-input" type="text" name="name" placeholder="Enter Your Full Name" required/>
            <label className="contact-label contact-email">Email</label>
            <input className="contact-input" type="email" name="email" placeholder="Enter Your Email" required/>
        </div>
       <div className="contact-div">
            <label className="contact-label">Message</label>
            <textarea className="contact-textarea" name="message" placeholder="Type a message to Makkiah" required></textarea>
       </div>
        <button type="submit" className="contact-submit">Send</button>
      </form>
  );
}

export default ContactForm;