import "./singleProject.scss";
import PhotoViewer from "../PhotoViewer/photoViewer";
function SingleProject() {

    return (
    <>
        <div className="singleProject-wrapper">
            <div className="singleProject-container">
                <div className="singleProject-logo">
                    Logo
                </div>
                <div className="singleProject-top">
                    <div className="singleProject-top-images">
                        <PhotoViewer/>
                    </div>
                    <div className="singleProject-top-text">
                        <h3 className="singleProject-top-text-title">
                            My time with.. Company Name
                        </h3>
                        <p className="singleProject-top-text-desc">
                            Created and updated websites for a Cincinnati, OH based healthcare journal, its subsidiaries, and IHCI annual events.
                        </p>
                    </div>
                </div>
                <div className="singleProject-bottom">
                    <p className="singleProject-bottom-desc">
                        Created and updated websites for a Cincinnati, OH based healthcare journal, its subsidiaries, and IHCI annual events.
                    </p>
                    <div className="singleProject-bottom-image"></div>
                </div>
            </div>
        </div>
    </>);
}

export default SingleProject;