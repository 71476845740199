import "./footer.scss";
import {CustomLink} from '../Navigation/navigation';
import ContactForm from "../Contact2/contact";

function Footer() {

    const footerInfo = {
            name: "Makkiah",
            links: [
                {
                    name: "Home",
                    to: "/home"
                },
                {
                    name: "Résumé",
                    to: "/resume"
                },
                // {
                //     name: "Bio",
                //     to: "/bio"
                // },
                // {
                //     name: "Web Projects",
                //     to: "/projects"
                // },
                {
                    name: "Music",
                    to: "/music"
                },
                {
                    name: "Blog",
                    to: "/blog",
                    subLinks: []
                },
                // {
                //     name: "Contact",
                //     to: "/contact"
                // }
            ],
            copyright: "Copyright © 2024 Makkiah.com"
        }

    return(
        <div className="footer-wrapper">
            <div className="footer-top">
                <div className="footer-left">
                    <h1 className="footer-name">{footerInfo.name}</h1>
                    <ul className="footer-links-ul">
                        {
                            footerInfo.links.map((link, key) => {

                                return (
                                    <CustomLink to={link.to} key={key}>{link.name}</CustomLink>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="footer-right" id="footer-contact">
                    <h2 className="footer-right-label">Contact Me</h2>
                    <ContactForm/>
                </div>
            </div>
            <div className="footer-bottom">
                    <p className="footer-copyright">{footerInfo.copyright}</p>
            </div>
        </div>
    )
}

export default Footer;