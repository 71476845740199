import './blog.scss';

function Blog() {

    return <>
        <div className='blog'>
            <p>Coming soon.</p>
        </div>
    </>
}

export default Blog;