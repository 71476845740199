import './App.scss';
import {Navigation} from './components/Navigation/navigation';
import Footer from "./components/Footer/footer";
import {Routes, Route} from 'react-router-dom';
import Home from './Pages/Home/home';
import ProjectsPage from './Pages/Projects/projectsPage';
import SingleProjectPage from './Pages/SingleProject/singleProjectPage';
import MusicPage from './Pages/Music/musicPage';
import ContactPage from './Pages/Contact/contactPage';
import ResumePage from './Pages/Resume/resumePage';
import BlogPage from './Pages/Blog/blogPage';
import Nopage from './Pages/Nopage/nopage';

function App() {

  return (
    <>
      <Navigation/>
      <div class='site-container'>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path="/home" element={<Home />}/>
          <Route path="/projects" element={<ProjectsPage />}/>
          <Route path="/projects/page" element={<SingleProjectPage />}/>
          <Route path="/music" element={<MusicPage />}/>
          <Route path="/contact" element={<ContactPage />}/>
          <Route path="/resume" element={<ResumePage />}/>
          <Route path="/blog" element={<BlogPage />}/>
          <Route path="*" element={<Nopage />}/>
        </Routes>
      </div>
      
      <Footer/>
    </>
  );
}

export default App;
