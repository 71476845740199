import ProjectsComponent from '../../components/Projects/projects';
import Websites from '../../components/Websites/websites';
import './projectsPage.scss';
import Quote from '../../components/Quote/quote';


function Projects(){

    return (
        <>
            <Quote/>
            <div className='projectsPage-container'>
                <h1 className='projectsPage-header'>Projects</h1>
                <p className='projectsPage-subtitle'>A list of websites that i've contributed to.</p>
                <ProjectsComponent /> 
                <Websites/>
            </div>
        </>
    );  
}

export default Projects;;