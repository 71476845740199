import Home from '../../components/Home/index';
// import Quote from '../../components/Quote/quote';
import Banner from '../../components/Banner/banner';


function HomePage() {

  return (
    <>
      <Banner/>
      {/* <Quote 
        quote="I want to be the very best, like no one ever was.."
        author="Jason Paige"
      /> */}
      <Home />
    </>
  );
}

export default HomePage;