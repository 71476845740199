import "./websites.scss";
import PDLogo from '../../assets/images/logos/3.png';
import IHCILogo from '../../assets/images/logos/4.png';
import UHLogo from '../../assets/images/logos/1.png';
import BSLogo from '../../assets/images/logos/5.png';
import OPDLogo from '../../assets/images/logos/2.png';
import AHISLogo from '../../assets/images/logos/6.png';
import CIRCLogo from '../../assets/images/job_assets/CIRC/CIRCLogo-Black.png';
// import NOLogo from '../../assets/images/logos/7.png';


function Websites(){

    const sites = [
        {
            key: 1,
            name: "Power Distributors",
            image: {
                url: `${PDLogo}`,
                alt: "Power Distributors website image"
            },
            btnLink : "https://powerdistributors.com"
        },
        {
            key: 2,
            name: "Briggs & Stratton",
            image: {
                url: `${BSLogo}`,
                alt: "Briggs & Stratton Online Store"
            },
            btnLink : "https://shop.briggsandstratton.com"
        },
        {
            key: 3,
            name: "Outdoor Power Direct",
            image: {
                url: `${OPDLogo}`,
                alt: "Outdoor Power Direct"
            },
            btnLink : "https://outdoorpowerdirect.com"
        },
        {
            key: 4,
            name: "Innovative Healthcare Institute",
            image: {
                url:`${IHCILogo}`,
                alt: "Innovative Healthcare Institute logo"
            },
            btnLink : "https://innovativehci.com"
        },
        {
            key: 5,
            name: "Cincy IRC",
            image: {
                url: `${CIRCLogo}`,
                alt: "Cincinnati Innovative Research Center"
            },
            btnLink : "https://cincyirc.com"
        },
        {
            key: 6,
            name: "Unique Wholesale ATL",
            image: {
                url: `${UHLogo}`,
                alt: "Unique Wholesale ATL"
            },
            btnLink : "https://www.uniquewholesaleatl.com"
        },
        // {
        //     key: 7,
        //     name: "Prevail or Perish",
        //     image: {
        //         url: `${NOLogo}`,
        //         alt: "Prevail or Perish"
        //     },
        //     btnLink : "https://prevailorperish.com"
        // },
        {
            key: 8,
            name: "AHIS",
            image: {
                url: `${AHISLogo}`,
                alt: "Advancing Healthcare Innovation Summit"
            },
            btnLink : "https://ahisummits.com"
        },
        {
            key: 9,
            name: "Pharmacoeconomics Forum",
            image: {
                url: '',
                alt: "Pharmacoeconomics Forum"
            },
            btnLink : "https://pharmacoeconomicsforum.com"
        },
    ]

    return (
        <>
            <section className="webs-wrapper">
                {/* <p className="webs-label">Websites I've worked on.</p> */}
                <div className="webs-container">
                    {sites.map((site, key, index) => {
                        return(
                            <div className="webs-card">
                                {site.image.url.length !== 0 ? <a className="webs-card-link" href={site.btnLink} target="blank"><img className="webs-card_image" alt={`${site.image.alt}`} src={`${site.image.url}`} key={site.key}/></a> : <a className="webs-card-link" href={site.btnLink} target="blank"><h2 className="webs-card_title">{site.name}</h2></a>}
                            </div>
                        )
                    })}
                </div>
            </section>
        </>
    )
};

export default Websites;