// import {ContactPage as ContactPageComponent} from '../../components/Contact/contact';
// import Quote from '../../components/Quote/quote';
import './contactPage.scss';
import ContactForm from '../../components/Contact2/contact';

function Contact() {

    return (
      <>
        {/* <Quote /> */}
        <div className='contactPage-container'>
            <h1 className='contactPage-header'>Contact</h1>
            <ContactForm/>
        </div>
      </>
    );
  }
  
  export default Contact;