import './resumePage.scss';
import ResumeComponent from '../../components/Resume/resume';
import Websites from '../../components/Websites/websites';
import { ShortBio } from '../../components/Bio/bio';
// import Quote from '../../components/Quote/quote';

function ResumePage(){
    
    return (
    <> 
        {/* <Quote 
            quote="I want to be the very best, like no one ever was.."
            author="Jason Paige"
        /> */}
        <div className="resumePage-container">
            <h1 className='resumePage-header'>Résumé</h1>
            <ResumeComponent/>
            <Websites/>
            <ShortBio />
        </div>
    </>)
}

export default ResumePage;