import './music.scss'

function Music() {

   

    const myMusic = [
        {
            src: "https://open.spotify.com/embed/track/1i21rvNoKPRCRDbiDT5aH8?utm_source=generator&theme=0",
            title: "Your High"
        },
        {
            src: "https://open.spotify.com/embed/track/7pUPJXmDJo66dusaQ57XRw?utm_source=generator",
            title: "Dark Side"
        },
        {
            src: "https://open.spotify.com/embed/track/3OzdB8ElPXqGgMdjov9ziC?utm_source=generator",
            title: "Got You Now"
        },
        {
            src: "https://open.spotify.com/embed/track/7DxDJCG8nlSJn6D71onZcP?utm_source=generator",
            title: "Boss Up"
        },
        {
            src: "https://open.spotify.com/embed/track/3mg7gPlQsBfLDRaUOMqOU2?utm_source=generator",
            title: "I Dont Wanna Lose"
        },
        {
            src: "https://open.spotify.com/embed/track/3ONx20bIHQ17kRRSIx1wgA?utm_source=generator",
            title: "Acting Funny"
        },
        {
            src: "https://open.spotify.com/embed/track/6MPuFeGQK66Sr6Qx0qXYqS?utm_source=generator",
            title: "Devil Upside Down"
        },
        {
            src: "https://open.spotify.com/embed/track/4QI1WzKGHzpld6GCUt9wvr?utm_source=generator",
            title: "Bury Marry"
        },
        {
            src: "https://open.spotify.com/embed/track/4BlSInK5ImGKpb7EfiV45O?utm_source=generator",
            title: "3 Bs"
        },
        {
            src: "https://open.spotify.com/embed/track/3BhQzN9dHGwdWqIMWboYYP?utm_source=generator",
            title: "Red Wine"
        },
        {
            src: "https://open.spotify.com/embed/album/6nboE3XeeQpiWE4Ek8Zp3O?utm_source=generator",
            title: "Vibes"
        },
        {
            src: "https://open.spotify.com/embed/track/7rfGtTJ47U3egCQmguTGqu?utm_source=generator",
            title: "Rouge One"
        }
    ]

    return (
        <div className="music-wrapper">
            <div className="music-container">
                { myMusic.map((music, key) => {
                    return (
                        <div className='music-card'>
                            <iframe className='spotify-embed' title={music.title} src={music.src} width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                        </div>
                    );
                })}
            </div>
        </div>)
}

export default Music;