import './blogPage.scss';
import Blog from '../../components/Blog/blog';

function BlogPage(){

    return <>
        <Blog/>
    </>
}

export default BlogPage;