import SingleProject from '../../components/SingleProject/singleProject';
import './singleProjectPage.scss';
import Quote from '../../components/Quote/quote';


function SingleProjectPage() {

    return (
    <>
        <Quote 
            quote="Wealth is what you don't see."
            author="Morgan Housel"
        />
        <div className="singleProjectPage-container">
            <SingleProject />
        </div>
    </>)
}

export default SingleProjectPage;