import './photoViewer.scss';
import $ from 'jquery';
import PD1 from '../../assets/images/job_assets/PD/photo1.png';
import PD2 from '../../assets/images/job_assets/PD/photo2.png';
import PD3 from '../../assets/images/job_assets/PD/photo3.png';
import PD4 from '../../assets/images/job_assets/PD/photo4.png';


    
function PhotoViewer(){
    // var thumbnails = document.getElementsByClassName('pv-thumb_link');
    
    
    const photoLib = {
        PD : {
            name : 'PD',
            slide1: `${PD1}`,
            title1: 'Power Distributors home page 1',
            slide2: `${PD2}`,
            title2: 'Power Distributors home page 2',
            slide3: `${PD3}`,
            title3: 'Power Distributors brand partners page 1',
            slide4: `${PD4}`,
            title4: 'Power Distributors brand partners page 2'
        }
    }

    $('.pv-thumb').eq(0).on('load');

    return (<>
        <div className="photoViewer-wrapper">
            <div id="pv-container">
                <img id="pv-image" src={photoLib.PD.slide1} alt={photoLib.PD.title1} />
            </div>
            <div id="pv-thumbnails">
                <button title={photoLib.PD.title1} className="pv-thumb_link" rel="noreferrer" aria-label={photoLib.PD.title1}>
                    <img className="pv-thumb_img" src={photoLib.PD.slide1} alt={photoLib.PD.title1}/>
                </button>
                <button title={photoLib.PD.title2} className="pv-thumb_link" rel="noreferrer" aria-label={photoLib.PD.title2}>
                    <img className="pv-thumb_img" src={photoLib.PD.slide2} alt={photoLib.PD.title2}/>
                </button>
                <button title={photoLib.PD.title3} className="pv-thumb_link" rel="noreferrer" aria-label={photoLib.PD.title3}>
                    <img className="pv-thumb_img active" src={photoLib.PD.slide3} alt={photoLib.PD.title3}/>
                </button>
                <button title={photoLib.PD.title4} className="pv-thumb_link" rel="noreferrer" aria-label={photoLib.PD.title4}>
                    <img className="pv-thumb_img" src={photoLib.PD.slide4} alt={photoLib.PD.title4}/>
                </button>
            </div>
        </div>
    </>)
}

export default PhotoViewer;