import './projects.scss'
import {Link} from 'react-router-dom';
import {useState} from 'react';
import PDLogo from '../../assets/images/job_assets/PD/pdLogo.png';
import IHCILogo from '../../assets/images/job_assets/IHCI/IHCILogo.jpeg';
import UHLogo from '../../assets/images/job_assets/UWS/UHATL.png';
import BSLogo from '../../assets/images/job_assets/BS/briggs-stratton-vector-logo.png';
import OPDLogo from '../../assets/images/job_assets/OPD/OPDLogo.png';
import AHISLogo from '../../assets/images/job_assets/AHIS/AHISLogo.png';
import CIRCLogo from '../../assets/images/job_assets/CIRC/CIRCLogo-Black.png';
import NOLogo from '../../assets/images/NoIMG.png';

function Projects() {

    const [imageIndex, setImageIndex] = useState(0);

    const projectInfo = [
        {
            key: 1,
            name: "Power Distributors",
            description: "N/A",
            image: {
                url: `${PDLogo}`,
                alt: "Power Distributors website image"
            },
            btnText : "View Website",
            btnLink : "https://powerdistributors.com",
            btnText2 : "More Info",
            btnLink2 : "/projects/page",
            icons : [
                <i className="fa-brands fa-vuejs project-card-icons" aria-label="Vue JS Icon" title="Vue JS"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-sass project-card-icons" aria-label="Sass Icon" title="Sass"></i>
            ],
            company: 'PD',
            logo : `${PDLogo}`
        },
        {
            key: 2,
            name: "Briggs & Stratton",
            description: "N/A",
            image: {
                url: `${BSLogo}`,
                alt: "Briggs & Stratton Online Store"
            },
            btnText : "View Website",
            btnLink : "https://shop.briggsandstratton.com",
            btnText2 : "More Info",
            btnLink2 : "/projects/page",
            icons : [
                <i className="fa-brands fa-shopify project-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js project-card-icons" aria-label="Javascript Icon" title="Javascript"></i>
            ]
        },
        {
            key: 3,
            name: "Outdoor Power Direct",
            description: "N/A",
            image: {
                url: `${OPDLogo}`,
                alt: "Outdoor Power Direct"
            },
            btnText : "View Website",
            btnLink : "https://outdoorpowerdirect.com",
            btnText2 : "More Info",
            btnLink2 : "/music",
            icons : [
                <i className="fa-brands fa-shopify project-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js project-card-icons" aria-label="Javascript Icon" title="Javascript"></i>
            ]
        },
        {
            key: 4,
            name: "Innovative Healthcare Institute",
            description: "Created and updated websites for a Cincinnati, OH based healthcare journal, its subsidiaries, and IHCI annual events.",
            image: {
                url:`${IHCILogo}`,
                alt: "Innovative Healthcare Institute logo"
            },
            btnText : "View Website",
            btnLink : "https://innovativehci.com",
            icons : [
                <i className="fa-brands fa-shopify project-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-wordpress project-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js project-card-icons" aria-label="Javascript Icon" title="Javascript"></i>
            ]
        },
        {
            key: 5,
            name: "Cincy IRC",
            description: "N/A",
            image: {
                url: `${CIRCLogo}`,
                alt: "Cincinnati Innovative Research Center"
            },
            btnText : "View Website",
            btnLink : "https://cincyirc.com",
            btnText2 : "More Info",
            btnLink2 : "/music",
            icons : [
                <i className="fa-brands fa-shopify project-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
            ]
        },
        {
            key: 6,
            name: "Unique Wholesale ATL",
            description: "N/A",
            image: {
                url: `${UHLogo}`,
                alt: "Unique Wholesale ATL"
            },
            btnText : "View Website",
            btnLink : "https://www.uniquewholesaleatl.com",
            icons : [
                <i className="fa-brands fa-shopify project-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-mailchimp project-card-icons" aria-label="Mailchimp Icon" title="Mailchimp"></i>,
                <i className="fa-brands fa-opencart project-card-icons" aria-label="OpenCart Icon" title="OpenCart"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
            ]
        },
        {
            key: 7,
            name: "Prevail or Perish",
            description: "N/A",
            image: {
                url: `${NOLogo}`,
                alt: "Prevail or Perish"
            },
            btnText : "View Website",
            btnLink : "https://prevailorperish.com",
            btnText2 : "More Info",
            btnLink2 : "/music",
            icons : [
                <i className="fa-brands fa-shopify project-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js project-card-icons" aria-label="Javascript Icon" title="Javascript"></i>
            ]
        },
        {
            key: 8,
            name: "AHIS",
            description: "N/A",
            image: {
                url: `${AHISLogo}`,
                alt: "Advancing Healthcare Innovation Summit"
            },
            btnText : "View Website",
            btnLink : "https://ahisummits.com",
            btnText2 : "More Info",
            btnLink2 : "/music",
            icons : [
                <i className="fa-brands fa-wordpress project-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js project-card-icons" aria-label="Javascript Icon" title="Javascript"></i>
            ]
        },
        {
            key: 9,
            name: "Pharmacoeconomics Forum",
            description: "N/A",
            image: {
                url: `${NOLogo}`,
                alt: "Pharmacoeconomics Forum"
            },
            btnText : "View Website",
            btnLink : "https://pharmacoeconomicsforum.com",
            btnText2 : "More Info",
            btnLink2 : "/music",
            icons : [
                <i className="fa-brands fa-wordpress project-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,
                <i className="fa-brands fa-css3-alt project-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 project-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js project-card-icons" aria-label="Javascript Icon" title="Javascript"></i>
            ]
        },
    ]

    function showNextImage() {
        setImageIndex(index => {
            if(index === projectInfo.length - 1) {
                return 0;
            } else {
               return index + 1;
            }
        })
        
        
    };

    function showPrevImage() {
        setImageIndex(index => {
            if(index === 0) {
                return projectInfo.length - 1;
            } else {
            return index - 1;
            }
        })
        
        
    };

    return (<>
        <section className="projects-wrapper" aria-label="Web Projects Slider">
            <a href="#after-jobs" className='skip-link'>
                Skip Web Projects Section
            </a>
            <div className="projects-container">
            {   projectInfo.map((project, key, index ) => {
                return (
                    <div className="project-card" key={project.key} style={{translate: `${-100 * imageIndex}%`}}>
                        <div className="project-card-top">
                            {project.image.length !== 0 ? <img src={`${project.image.url}`} className="project-card-img" alt={`${project.image.alt}`} key={project.key} aria-hidden={imageIndex !== project.key}/> : "No Image"}
                        </div>
                        <div className="project-card-bottom">
                            <h3 className="project-name">{project.name}</h3>
                            <div className='project-icons'>{project.icons}</div>
                            <p className='project-desc'>{project.description}</p>
                            <div className='project-btns'>
                                <a href={project.btnLink} className='project-viewBtn' aria-label={`View ${project.name}`} target='blank'>{project.btnText}</a>
                                {project.btnLink2 ? <Link to={project.btnLink2} className='project-infoBtn'>{project.btnText2}</Link> : ""}
                            </div>
                            
                        </div>
                        
                    </div>
                );
            })}
            </div>
            
            
            <i className="fa-solid fa-circle-chevron-left projects-arrow projects-arrow-left" onClick={showPrevImage} aria-label="View Previous Image"></i>
            <div className="projects-nav">
                { projectInfo.map((_, index) => (
                    <button key={index} className='projects-nav-btn' onClick={() => setImageIndex(index)} aria-label={`View Project ${index + 1}`}>
                        {
                            index === imageIndex ? <i className="fa-regular fa-circle projects-nav-icon-selected"></i> : <i className="fa-regular fa-circle projects-nav-icon"></i>
                        }
                    </button>
                    
                ))}
            </div>
            <i className="fa-solid fa-circle-chevron-right projects-arrow projects-arrow-right" onClick={showNextImage} aria-label="View Next Image"></i>
            <div id='after-jobs'/>
        </section>
    </>
        
    );
}

export default Projects;